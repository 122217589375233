<template>
  <div class="container for-you">
    <div class="heading heading-flex mb-3">
      <div class="heading-left">
        <router-link
          :to="{
            name: 'GoodsStatic',
            query: {
              specialFilter: 'Popular',
            },
          }"
        >
          <h2 class="title">Рекомендации для Вас</h2>
        </router-link>
      </div>

      <div class="heading-right">
        <router-link to="#" class="title-link">
          Смотреть все предложения
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M1 7.9999C1 7.86729 1.05268 7.74011 1.14645 7.64635C1.24021 7.55258 1.36739 7.4999 1.5 7.4999H13.293L10.146 4.3539C10.0521 4.26001 9.99937 4.13267 9.99937 3.9999C9.99937 3.86712 10.0521 3.73979 10.146 3.6459C10.2399 3.55201 10.3672 3.49927 10.5 3.49927C10.6328 3.49927 10.7601 3.55201 10.854 3.6459L14.854 7.6459C14.9006 7.69234 14.9375 7.74752 14.9627 7.80827C14.9879 7.86901 15.0009 7.93413 15.0009 7.9999C15.0009 8.06567 14.9879 8.13079 14.9627 8.19153C14.9375 8.25228 14.9006 8.30745 14.854 8.3539L10.854 12.3539C10.7601 12.4478 10.6328 12.5005 10.5 12.5005C10.3672 12.5005 10.2399 12.4478 10.146 12.3539C10.0521 12.26 9.99937 12.1327 9.99937 11.9999C9.99937 11.8671 10.0521 11.7398 10.146 11.6459L13.293 8.4999H1.5C1.36739 8.4999 1.24021 8.44722 1.14645 8.35345C1.05268 8.25968 1 8.13251 1 7.9999Z"
              fill="#777777"
            />
          </svg>
        </router-link>
      </div>
    </div>

    <div class="products">
      <div class="row justify-content-center">
        <div
          class="col-6 col-md-4 col-lg-3"
          v-for="(product, index) in products"
          :key="index"
        >
          <RecomendationCard
            :product="product"
            :labels="labelCollections[index]"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { defineProps, toRef } from "vue";
import { GoodProduct } from "@/api/models/GoodProduct";
import RecomendationCard from "../../elements/products/RecomendationCard.vue";
import { CommonCategory } from "@/api/models/CommonCategory";

const props = defineProps<{
  products: (GoodProduct & { categories: CommonCategory[] })[];
}>();

const products = toRef(props, "products");
const labelCollections = [
  {
    top: false,
    new: false,
    offer: false,
  },
  {
    top: false,
    new: false,
    offer: false,
  },
  {
    top: true,
    new: false,
    offer: true,
  },
  {
    top: false,
    new: true,
    offer: true,
  },
  {
    top: true,
    new: true,
    offer: false,
  },
  {
    top: true,
    new: false,
    offer: false,
  },
  {
    top: true,
    new: true,
    offer: false,
  },
  {
    top: true,
    new: false,
    offer: false,
  },
];
</script>

<style scoped>
.title-link {
  display: flex;
  align-items: center;
  gap: 5px;
  color: #777777;
}

.title-link:hover {
  color: var(--primary_bg) !important;
}

.title-link:hover svg path {
  fill: var(--primary_bg) !important;
}

@media screen and (max-width: 768px) {
  .heading-right {
    display: flex;
    justify-content: center;
  }
}
</style>

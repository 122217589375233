import { CommonCategory } from "@/api/models/all";
import axios from "@/repositories/repository";
import { QueryParams } from "../types";

export const fetchCategories = async (
  params?: QueryParams
): Promise<{ data: CommonCategory[] }> => {
  const { data } = await axios.get("admitad/categories", {
    params,
  });
  return data;
};

<template>
  <button class="btn-clear" @click="$emit('clear')">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M5.83398 14.0834L14.084 5.83337"
        :stroke="color"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M14.084 14.0834L5.83398 5.83337"
        :stroke="color"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  </button>
</template>

<script setup lang="ts">
import { defineEmits, defineProps, toRefs, withDefaults } from "vue";

const $emit = defineEmits(["clear"]);

const props = withDefaults(
  defineProps<{
    color?: string;
  }>(),
  {
    color: "#797979",
  }
);
const { color } = toRefs(props);
</script>

<style scoped>
.btn-clear {
  cursor: pointer;
}
</style>

import axios from "@/repositories/repository";
import { CustomerData, UserData } from "../types";

export const login = async (params: {
  userName: string;
  password: string;
}): Promise<UserData> => {
  const { data } = await axios.post("v1/login", params);
  return data;
};

// export const registrationRequest = async (params: {
//   token: string;
// }): Promise<UserData> => {
//   const { data } = await axios.post("v1/login-token", params);
//   return data;
// };

export const recoveryPasswordPhone = async (params: {
  phone: string;
}): Promise<UserData> => {
  const { data } = await axios.post("v1/login/restore-send", params);
  return data;
};

export const logoutRequest = async (): Promise<void> => {
  await axios.get("v1/logout");
};

export const recoveryPasswordRequest = async (params: {
  phone: string;
  code: string;
  newPassword: string;
  newPasswordConfirm: string;
}): Promise<UserData> => {
  const { data } = await axios.post("v1/login/restore-verify", params);
  return data;
};

export const fetchUser = async (): Promise<CustomerData> => {
  const { data } = await axios.get("v1/login/test");
  return data;
};

export const fetchUserInfo = async (): Promise<CustomerData> => {
  const { data } = await axios.get("v1/login/info");
  return data;
};

<template>
  <div class="mobile-menu-container">
    <div class="mobile-menu-wrapper">
      <span class="mobile-menu-close" @click="hideMobileMenu">
        <img src="../../../assets/newImg/icons/modal-close.svg" alt="" />
      </span>
      <form
        action="#"
        method="get"
        class="mobile-search"
        @submit.prevent="submitSearchForm"
      >
        <label for="mobile-search" class="sr-only">Поиск</label>
        <input
          type="text"
          class="form-control borderCus"
          name="mobile-search"
          id="mobile-search"
          v-model="searchTerm"
          placeholder="Поиск по каталогу..."
          required
        />
        <button class="btn btn-primary" type="submit">
          <img src="../../../assets/newImg/icons/search.svg" alt="" />
        </button>
      </form>
      <tabs class="nav-pills-mobile text-center" :data="tabsData"></tabs>
      <div class="tab-content">
        <div
          class="tab-pane fade"
          :class="{ show: tabsData[1].active, active: tabsData[1].active }"
          id="mobile-nav"
        >
          <nav class="mobile-nav">
            <ul class="mobile-menu">
              <li :class="currentPath === '/' ? 'active' : ''">
                <router-link to="/" class="sf-with-ul" @click="hideMobileMenu"
                  >Главная</router-link
                >
              </li>
              <li
                :class="
                  currentPath === '/shop/catalogue/admitads' ? 'active' : ''
                "
              >
                <router-link
                  :to="{
                    name: 'AdmitadsStatic',
                  }"
                  @click="hideMobileMenu"
                  >Спецпредложения</router-link
                >
              </li>
              <li>
                <a
                  href="https://novikom.ru/individuals/special-offers/"
                  class="sf-with-ul"
                  target="_blank"
                  @click="hideMobileMenu"
                  >Акции</a
                >
              </li>
            </ul>
          </nav>
        </div>
        <div
          class="tab-pane fade"
          id="mobile-categories"
          :class="{ show: tabsData[0].active, active: tabsData[0].active }"
        >
          <nav class="mobile-cats-nav">
            <ul class="mobile-cats-menu">
              <li
                class="item-cats-lead"
                :class="
                  currentPath === '/shop/catalogue/certificates' ? 'active' : ''
                "
              >
                <router-link
                  :to="{
                    name: 'CertificatesStatic',
                    query: {
                      specialFilter: 'Popular',
                    },
                  }"
                  @click="hideMobileMenu"
                  >Подарочные сертификаты</router-link
                >
              </li>
              <li class="item-cats-lead">
                <router-link
                  :to="{
                    name: 'Travels',
                  }"
                  class="sf-with-ul"
                  @click="hideMobileMenu"
                  >Путешествия</router-link
                >
              </li>
              <li
                :class="currentPath === '/shop/catalogue/goods' ? 'active' : ''"
              >
                <router-link
                  :to="{
                    name: 'GoodsStatic',
                    query: {
                      specialFilter: 'Popular',
                    },
                  }"
                  @click="hideMobileMenu"
                  >Товары</router-link
                >
              </li>
              <li>
                <router-link
                  :to="{
                    name: 'Charities',
                  }"
                  @click="hideMobileMenu"
                  >Благотворительность
                </router-link>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { ref, onMounted, computed } from "vue";
import Tabs from "@/components/elements/Tabs.vue";
import { mobileMenu, hideMobileMenu } from "@/utilities/common.js";
import { useRouter, useRoute } from "vue-router";

const router = useRouter();
const route = useRoute();
const tabsData = ref([
  {
    id: "mobile-categories",
    title: "Потратить бонусы",
    active: true,
  },
  {
    id: "mobile-nav",
    title: "Меню",
  },
]);
const searchTerm = ref("");

const currentPath = computed(() => {
  return route.path;
});

onMounted(() => {
  mobileMenu();
});

const submitSearchForm = () => {
  hideMobileMenu();
  router.push({
    path: "/shop/catalogue/goods",
    query: {
      searchTerm: searchTerm.value,
    },
  });
};
</script>

<style scoped>
.borderCus {
  border-top-left-radius: 8px !important;
  border-bottom-left-radius: 8px !important;
}
.btn {
  border-top-right-radius: 8px !important;
  border-bottom-right-radius: 8px !important;
}
</style>

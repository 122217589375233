<template>
  <div class="product product-2 mb-0 flex flex-column good-card">
    <figure class="product-media" id="category_media">
      <template v-if="showLabel">
        <span
          class="product-label label-circle label-new"
          v-if="randomLabelNumner === 1"
          >Новинка</span
        >
        <span
          class="product-label label-circle label-sale"
          v-if="currentIndex === 0 || randomLabelNumner === 2"
          >Выгодно</span
        >
        <span
          class="product-label label-circle label-top"
          v-if="currentIndex === 0 || randomLabelNumner === 3"
          >Топ</span
        >
      </template>
      <router-link :to="currentLink" class="top_products">
        <img :src="`${product?.image}`" alt="Product" class="product-image" />
      </router-link>
    </figure>
    <div class="product-body">
      <div class="product-cat">
        <span v-for="category in product?.categories" :key="category.id">
          <router-link
            v-if="isGoodProduct(product)"
            :to="{
              name: 'GoodsStatic',
              query: { categoryIds: category.id },
            }"
          >
            {{ decodeName(category.name as string) }}
          </router-link>
          <router-link v-else to="/shop/catalogue/certificates"
            >Подарочный сертификат</router-link
          >
        </span>
      </div>
      <h3 class="product-title">
        <router-link :to="currentLink">{{
          decodeName(product?.name as string)
        }}</router-link>
      </h3>
      <div
        v-if="product?.hasOwnProperty('price')"
        class="product-price"
        style="gap: 5px"
      >
        <span v-if="!isGoodProduct(product)">от</span>
        <PriceFormatted
          :price="(product as GoodProduct).price ?? 0"
          price-name="бонусов"
        />
      </div>
      <div class="mb-4"></div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { defineProps, toRef, computed, withDefaults } from "vue";
import { GoodProduct } from "@/api/models/GoodProduct";
import { generateRandomNumber } from "@/utilities/number";
import { Certificate } from "@/api/models/Certificate";
import PriceFormatted from "@/components/elements/typography/PriceFormatted.vue";
import { CommonCategory } from "@/api/models/CommonCategory";
import { useRoute } from "vue-router";
import { decodeHtml } from "@/utilities/text";

type Props =
  | (GoodProduct & { type: "Good" } & { categories: CommonCategory[] })
  | (Certificate & { type: "Cert" } & { categories: CommonCategory[] });

const props = withDefaults(
  defineProps<{
    product?: Props;
    currentIndex?: number;
    showLabel?: boolean;
  }>(),
  {
    showLabel: true,
  }
);
const product = toRef(props, "product");
const currentIndex = toRef(props, "currentIndex");
const showLabel = toRef(props, "showLabel");
const route = useRoute();

const isGoodProduct = (
  product?: Props
): product is GoodProduct & { type: "Good" } & {
  categories: CommonCategory[];
} => {
  return !!(product && product.type === "Good");
};
const randomLabelNumner = generateRandomNumber(1, 3);
const currentLink = computed(() => {
  return isGoodProduct(product.value)
    ? {
        name: "Good",
        params: { id: product?.value?.id ?? 0 },
        query: {
          categoryIds: currentCategories.value,
        },
      }
    : {
        name: "Certificate",
        params: { id: product?.value?.id ?? 0 },
        query: {
          vendor: product?.value?.vendorId ?? 0,
          categoryIds: currentCategories.value,
        },
      };
});

const currentCategories = computed(() => {
  return (
    route.query.categoryIds ||
    product.value?.categories?.map((category) => category.id).join(",")
  );
});

const decodeName = (name: string) => {
  return decodeHtml(name);
};
</script>
<style>
.product {
  width: 100%;
}

#category_carousel .top_products,
.ozon_product {
  display: flex !important;
  flex-direction: column !important;
  width: 100% !important;
}

#category_carousel {
  width: 100%;
}

.good-card .product-media {
  max-height: none !important;
}

.top_products img {
  height: auto;
}
</style>
